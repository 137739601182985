import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';

function FixturesNotAvailable(props) {
  return (
    <ListGroup>
      <ListGroup.Item className="row-style-with-flexbox">
        <p>
          <strong>
            <em>{props.message}</em>
          </strong>
        </p>
        {props.action ?  <Button
          variant="primary"
          onClick={() => {
            props.setKey(props.action);
          }}
        >
          {'Go to ' + props.action + " fixture"}
        </Button>: ''}
      </ListGroup.Item>
    </ListGroup>
  );
}

export default FixturesNotAvailable;
