import React, { Component } from 'react';
import { connect } from 'react-redux';
import Badge from 'react-bootstrap/Badge';

import { deleteGames } from '../../_actions';

class PillButtonComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isEditIconClicked: true, getStationById: '', value: '' };
  }
  render() {
    return this.props.stations
      ? this.props.stations.map((station, index) => {
          return (this.props.selectedOptions &&
            this.props.selectedOptions.includes(station.name)) ||
            this.props.noSelectedOptions ? (
            <Badge
              pill
              variant="primary"
              key={index}
              className="main-form-edit-fixtures-pill-badge"
            >
              <span>
                {station.name}
                {this.props.action ? (
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={e => {
                      this.props.deleteGames(this.props.fixture_id, station.id);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                ) : null}
              </span>
            </Badge>
          ) : null;
        })
      : null;
  }
}

export default connect(null, { deleteGames })(PillButtonComponent);
