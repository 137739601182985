import React from "react";
import { connect } from "react-redux";

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import { deleteDictionaryEntryById, getFixturesById } from "../../_actions";

const GetDictionary = (props) => {
	const listItems = props.dictionary_entries ? (
		props.dictionary_entries.reverse().map((dictionaryEntry) => (
			<tr key={dictionaryEntry.id}>
				<td>
					<p>{dictionaryEntry.matched_word}</p>
				</td>
				<td>
					<p>{dictionaryEntry.replacement}</p>
				</td>
				<td>
					<p>{dictionaryEntry.entry_type}</p>
				</td>
				<td>
					{/* <Button
            variant="info"
            onClick={() => {
              props.setKey('edit');
              props.getFixturesById(fixture.id);
            }}
          >
            Edit
          </Button> */}
					<Button
						variant="danger"
						onClick={() => {
							props.deleteDictionaryEntryById(dictionaryEntry.id);
						}}
					>
						Delete
					</Button>
				</td>
			</tr>
		))
	) : (
		<tr>
			<td colSpan="4">{props.message}</td>
		</tr>
	);
	return (
		<div>
			<Table striped bordered responsive>
				<thead>
					<tr>
						<th>Word</th>
						<th>Replacement</th>
						<th>Type</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>{listItems}</tbody>
			</Table>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		dictionary_entries: state.dictionary_entries,
	};
}
export default connect(mapStateToProps, {
	deleteDictionaryEntryById,
	getFixturesById,
})(GetDictionary);
