import React, { useState } from "react";
import { connect } from "react-redux";
import $ from "jquery";

import { Formik } from "formik";
import { Button, Form, Col } from "react-bootstrap";

import textInputField from "../inputs/field/Text";
import { createPosterEntry } from "../../_actions";

import validationSchema from "../../_utils/validation";
import LogoSelector from "../inputs/field/LogoSelector";
import PosterColor from "../inputs/field/PosterColorSelector";

const ManagePosters = (props) => {
	const [color1, setSelectedColor1] = useState("");
	const [color2, setSelectedColor2] = useState("");
	const [color3, setSelectedColor3] = useState("");

	return (
		<Formik
			enctype="multipart/form-data"
			initialValues={{
				name: "",
				bar_logo_type: "",
				bkg_image: "",
			}}
			validationSchema={validationSchema.PosterEntrySchema}
			onSubmit={(values) => {
				const { name, bkg_image, example, logo_sc, bar_logo_type } = values;

				const selectedColors = {
					color1,
					color2,
					color3,
				};

				const valuesWithColors = {
					...values,
					...selectedColors,
				};

				props.createPosterEntry(valuesWithColors, true);

				createPosterEntry({
					name,
					color1,
					color2,
					color3,
					bar_logo_type,
					bkg_image,
					example,
					logo_sc,
				});
			}}
		>
			{({
				handleSubmit,
				handleChange,
				handleBlur,
				setFieldValue,
				values,
				touched,
				submitForm,
				errors,
			}) => (
				<Form noValidate onSubmit={handleSubmit}>
					<Form.Group controlId="name">
						{textInputField(
							"name",
							"poster's name",
							handleBlur,
							values,
							handleChange,
							touched,
							errors,
							false,
							null
						)}
					</Form.Group>

					<Form.Group controlId="formColor1">
						<Form.Label>Pick a color for the match day</Form.Label>
						<PosterColor setSelectedColor={setSelectedColor1} />
					</Form.Group>
					<Form.Group controlId="formColor2">
						<Form.Label>Pick a color for the day </Form.Label>
						<PosterColor setSelectedColor={setSelectedColor2} />
					</Form.Group>
					<Form.Group controlId="formColor3">
						<Form.Label>Pick a color for the month</Form.Label>
						<PosterColor setSelectedColor={setSelectedColor3} />
					</Form.Group>

					<Form.Group controlId="exampleForm.ControlSelect1">
						<Form.Label>Choose bar logo type</Form.Label>
						<LogoSelector
							onChange={handleChange}
							errors={errors}
							touched={touched}
							selectType="bar_logo_type"
						/>
					</Form.Group>

					<Form.File controlId="formFileName">
						<Form.File.Label>Upload background image</Form.File.Label>
						<br />
						<input
							style={{ paddingBottom: "10px" }}
							id="bkg_image"
							accept="jpg/png"
							name="bkg_image"
							type="file"
							onChange={(event) => {
								setFieldValue("bkg_image", event.currentTarget.files[0]);
							}}
						/>
						<br />
						<Form.File.Label>Upload example</Form.File.Label>
						<br />
						<input
							style={{ paddingBottom: "10px" }}
							id="example"
							accept="jpg/png"
							name="picture"
							type="file"
							onChange={(event) => {
								setFieldValue("example", event.currentTarget.files[0]);
							}}
						/>
						<br />
						<Form.File.Label>Upload logo</Form.File.Label>
						<br />
						<input
							style={{ paddingBottom: "10px" }}
							id="logo_sc"
							accept="jpg/png"
							name="picture"
							type="file"
							onChange={(event) => {
								setFieldValue("logo_sc", event.currentTarget.files[0]);
							}}
						/>
					</Form.File>

					<Form.Row>
						<Form.Group as={Col} controlId="formCreateFixture">
							{$("#logo_sc").val() &&
							$("#example").val() &&
							$("#bkg_image").val() ? (
								<Button
									id="submit"
									variant="info"
									type="submit"
									className="main-form-fixtures-button"
								>
									Create Poster
								</Button>
							) : (
								<Button
									id="submit"
									variant="info"
									type="submit"
									className="main-form-fixtures-button"
									disabled
								>
									Create Poster
								</Button>
							)}
						</Form.Group>
					</Form.Row>
				</Form>
			)}
		</Formik>
	);
};

export default connect(null, { createPosterEntry })(ManagePosters);
