import { sportsTypes } from '../_constants';

function sportsReducer(state, action) {
  switch (action.type) {
    case sportsTypes.GET_SPORTS:
      return { ...state, sports: action.sport_objects };
    default:
      return state;
  }
}

export default sportsReducer;
