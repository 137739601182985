import React from 'react';
import { connect } from 'react-redux';

import { Formik, ErrorMessage } from 'formik';
import { Button, Form } from 'react-bootstrap';
import moment from 'moment';

import DatePickerInputField from '../inputs/field/DatePicker';
import textInputField from '../inputs/field/Text';
import SelectorInputField from '../inputs/field/Selector';

import { getFixtures } from '../../_actions';

const FindFixturesForm = props => {
  return (
    <div>
      <Formik
        initialValues={{
          sports: '',
          tags: '',
          start_date: moment().startOf("day").toDate(),
          end_date: moment().add(7, "days")
            .endOf("day")
            .toDate()
        }}
        onSubmit={values => {
          values['sports'] = values['sports']
            ? `${values['sports']}`.split()
            : [];
          values['tags'] = values['tags']
            ? `${values['tags']}`.split()
            : [];
          values['start_date'] = values['start_date']
            ? moment(values['start_date']).format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD');
          values['end_date'] = values['end_date']
            ? moment(values['end_date']).format('YYYY-MM-DD')
            : moment().add(7, 'days').format('YYYY-MM-DD');
          props.getFixtures(values);
        }}
      >
        {({
          handleSubmit,
          submitForm,
          handleBlur,
          handleChange,
          setFieldValue,
          values,
          touched,
          errors
        }) => (
            <Form
              noValidate
              onSubmit={handleSubmit}
              className="main-form-find-fixtures"
            >
              <div>
                <Form.Label>Sport type</Form.Label>
                <SelectorInputField
                  onChange={e => {
                    e.persist();
                    setFieldValue('sports', e.target.value, false);
                    submitForm();
                  }}
                  errors={errors}
                  touched={touched}
                  selectType="sports"
                />
              </div>
              <div className="main-form-find-fixtures-datepicker-field">
                <DatePickerInputField
                  name="start_date"
                  value={values.start_date}
                  onChange={setFieldValue}
                  placeholder="Start date"
                  submitForm={submitForm}

                />
                <ErrorMessage
                  name="start_date"
                  component="div"
                  className="text-danger"
                />
                <DatePickerInputField
                  name="end_date"
                  value={values.end_date}
                  onChange={setFieldValue}
                  placeholder="End date"
                  submitForm={submitForm}
                />
                <ErrorMessage
                  name="end_date"
                  component="div"
                  className="text-danger"
                />
              </div>
              {textInputField(
                'tags',
                'more search items',
                handleBlur,
                values,
                handleChange,
                touched,
                errors
              )}
              <Button
                variant="primary"
                type="submit"
                className="main-form-fixtures-button"
              >
                Search
            </Button>
            </Form>
          )}
      </Formik>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    fixtures: state.fixtures
  };
}

export default connect(mapStateToProps, { getFixtures })(FindFixturesForm);
