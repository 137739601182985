import { fixturesTypes } from '../_constants';

function fixturesReducer(state, action) {
  switch (action.type) {
    case fixturesTypes.GET_FIXTURES:
      return { ...state, fixtures: action.payload };
    case fixturesTypes.GET_FIXTURE_BY_ID:
      return {
        ...state,
        fixture_created: state.fixture_created
          ? state.fixture_created.map(fixture => {
              if (fixture.id === action.payload.id) {
                fixture = action.payload;
              }
              return fixture;
            })
          : [],
        fixture_edited: state.fixture_edited
          ? state.fixture_edited.map(fixture => {
              if (fixture.id === action.payload.id) {
                fixture = action.payload;
              }
              return fixture;
            })
          : [],
        fixture: action.payload,
        fixtures: state.fixtures
          ? state.fixtures.map(fixture => {
              if (fixture.id === action.payload.id) {
                fixture = action.payload;
              }
              return fixture;
            })
          : []
      };
    case fixturesTypes.CREATE_FIXTURE:
      if(action.payload === null || action.payload === undefined) {
        return state
      } else {
        if (state.fixture_created) {
          return {
            ...state,
            fixture_created: state.fixture_created.concat(action.payload)
          };
        }
        return { ...state, fixture_created: [action.payload] };
      }      
    case fixturesTypes.EDIT_FIXTURE:
      if(action.payload === null || action.payload === undefined) {
        return state
      } else {
        return { ...state, fixture_edited: [action.payload] };
      }      
    case fixturesTypes.DELETE_FIXTURE:
      return {
        ...state,
        fixtures:
          state.fixtures === undefined
            ? []
            : state.fixtures.filter(item => item.id !== action.id),
        fixture_created:
          state.fixture_created === undefined
            ? []
            : state.fixture_created.filter(item => item.id !== action.id),
        fixture_edited:
          state.fixture_edited === undefined
            ? []
            : state.fixture_edited.filter(item => item.id !== action.id),
        fixture_deleted:
          action.payload.success.charAt(0).toUpperCase() +
          action.payload.success.slice(1)
      };
    default:
      return state;
  }
}

export default fixturesReducer;
