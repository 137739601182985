import { clientTypes } from '../_constants';

function clientsReducer(state, action) {
  switch (action.type) {
    case clientTypes.GET_CLIENTS:
      return { ...state, clients: action.payload };
    case clientTypes.EDIT_CLIENTS:
      return {
        ...state,
        clients: state.clients.map(client => {
          console.log(action.payload.client.id);
          if (client.id === action.payload.client.id) {
            client.currency_id = action.payload.client.currency_id;
            client.need_pay = action.payload.client.need_pay;
          }
          return client;
        })
      };
    default:
      return state;
  }
}

export default clientsReducer;
