import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

import LoginForm from '../../components/form/Login';
import AlertMessage from '../../components/message/Alert';
import { login } from '../../_actions/index';

import './Login.scss';

function LoginPage(props) {
  return localStorage.getItem('authentication') ? (
    <Redirect to="/" />
  ) : (
    <div>
      {props.error_message !== undefined &&
      props.authentication === undefined ? (
        <AlertMessage message={props.error_message} variant="danger" />
      ) : null}
      <div className="LoginPage-body">
        <div className="LoginPage-layout">
          <img
            src={require('../../assets/company-name-and-logo.png')}
            alt="logo"
          />
          <LoginForm login={props.login} />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    error_message: state.error_message
  };
}

export default connect(
  mapStateToProps,
  { login }
)(LoginPage);

LoginPage.propTypes = {
  authentication: PropTypes.string,
  error_message: PropTypes.string
};
