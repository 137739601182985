import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  createStations,
  createGames,
  getStations
} from '../../../_actions/index';

import ButtonTypeaheadFormComponent from './Button';
import PillButtonComponent from '../../pill';
import TypeaheadInputComponent from '../../inputs/Typeahead';

class TypeaheadFormField extends Component {
  constructor(props) {
    super(props);
    this.state = { SelectedOptions: [], isAddChannelButtonClicked: false };
  }
  render() {
    const stationsPins =
      this.state.SelectedOptions && this.props.stations ? (
        <PillButtonComponent
          stations={this.props.stations}
          selectedOptions={this.state.SelectedOptions}
        />
      ) : null;
    return (
      <div>
        {this.state.SelectedOptions && this.state.SelectedOptions.length > 0 ? (
          <div>
            Selected Channels:
            {stationsPins}
          </div>
        ) : null}
        <TypeaheadInputComponent
          newSelectionPrefix="Add new channel: "
          stations={this.props.stations}
          onChange={selected => {
            this.setState({
              SelectedOptions: selected
            });
          }}
          selectedOptions={this.state.SelectedOptions}
          placeholder="Add new or search for channels"
        />
        <ButtonTypeaheadFormComponent
          fixture_id={this.props.fixture_id}
          stations={this.props.stations}
          selectedOptions={this.state.SelectedOptions}
          isAddChannelButtonClicked={this.state.isAddChannelButtonClicked}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    stations: state.stations
  };
}

export default connect(mapStateToProps, {
  createStations,
  createGames,
  getStations
})(TypeaheadFormField);
