import React from "react";
import { connect } from "react-redux";

import { Formik, ErrorMessage } from "formik";
import { Button, Form, Col } from "react-bootstrap";

import textInputField from "../inputs/field/Text";
import SelectorDictionaryEntry from "../inputs/field/SelectorDictionaryEntry";

import { createDictionaryEntry } from "../../_actions";
import validationSchema from "../../_utils/validation";

const DictionaryEntryForm = (props) => (
	<div>
		<Formik
			initialValues={{
				matched_word: "",
				replacement: "",
				entry_type: "",
			}}
			validationSchema={validationSchema.DictionaryEntrySchema}
			onSubmit={(values) => {
				props.createDictionaryEntry(values, true);
			}}
		>
			{({
				handleSubmit,
				handleChange,
				handleBlur,
				setFieldValue,
				values,
				touched,
				submitForm,
				errors,
			}) => (
				<Form noValidate onSubmit={handleSubmit}>
					<Form.Group controlId="exampleForm.ControlSelect1">
						<Form.Label>Entry type</Form.Label>
						<SelectorDictionaryEntry
							onChange={handleChange}
							errors={errors}
							touched={touched}
							selectType="entry_type"
						/>
					</Form.Group>
					<Form.Group controlId="validationFormikTitle">
						{textInputField(
							"matched_word",
							"word",
							handleBlur,
							values,
							handleChange,
							touched,
							errors,
							values.entry_type == "country_competition"
								? "Competition"
								: "Matched Word"
						)}
					</Form.Group>
					<Form.Group controlId="formGroupName">
						{textInputField(
							"replacement",
							"replacement",
							handleBlur,
							values,
							handleChange,
							touched,
							errors,
							values.entry_type == "country_competition"
								? "Country"
								: "Replacement"
						)}
					</Form.Group>
					<Form.Row>
						<Form.Group as={Col} controlId="formCreateFixture">
							<Button
								variant="info"
								type="submit"
								className="main-form-fixtures-button"
							>
								Create Dictionary Entry
							</Button>
						</Form.Group>
					</Form.Row>
				</Form>
			)}
		</Formik>
	</div>
);

export default connect(null, { createDictionaryEntry })(DictionaryEntryForm);
