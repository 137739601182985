import authenticationReducer from "./authentication";
import fixturesReducer from "./fixtures";
import stationsReducer from "./stations";
import dictionaryEntriesReducer from "./dictionaryEntries";
import clientsReducer from "./client";
import sportsReducer from "./sports";
import posterEntriesReducer from "./posterEntries";

import {
	fixturesTypes,
	alertType,
	authTypes,
	stationsTypes,
	gamesTypes,
	dictionaryEntryTypes,
	clientAccountTypes,
	clientTypes,
	sportsTypes,
	posterEntryTypes,
} from "../_constants";
import clientAccountsReducer from "./clientAccounts";

function rootReducer(state = {}, action) {
	switch (action.type) {
		case authTypes.LOGIN_AUTHENTICATION:
			return authenticationReducer(state, action);
		case authTypes.LOGIN_ERROR:
			return authenticationReducer(state, action);
		case authTypes.LOGIN_SUCCESS:
			return authenticationReducer(state, action);
		case alertType.ALERT_ACTION:
			return authenticationReducer(state, action);
		case authTypes.LOG_OUT:
			return {};
		case fixturesTypes.GET_FIXTURES:
			return fixturesReducer(state, action);
		case fixturesTypes.GET_FIXTURE_BY_ID:
			return fixturesReducer(state, action);
		case fixturesTypes.CREATE_FIXTURE:
			return fixturesReducer(state, action);
		case gamesTypes.CREATE_GAMES:
			return {
				...state,
				game: action.payload,
			};
		case gamesTypes.DELETE_GAMES:
			return {
				...state,
				game: action.payload,
			};
		case fixturesTypes.EDIT_FIXTURE:
			return fixturesReducer(state, action);
		case fixturesTypes.DELETE_FIXTURE:
			return fixturesReducer(state, action);
		case stationsTypes.GET_STATIONS:
			return stationsReducer(state, action);
		case stationsTypes.EDIT_STATIONS:
			return stationsReducer(state, action);
		case stationsTypes.CREATE_STATIONS:
			return stationsReducer(state, action);
		case stationsTypes.DELETE_STATIONS:
			return stationsReducer(state, action);
		case dictionaryEntryTypes.GET_ENTRIES:
			return dictionaryEntriesReducer(state, action);
		case dictionaryEntryTypes.CREATE_ENTRY:
			return dictionaryEntriesReducer(state, action);
		case dictionaryEntryTypes.DELETE_ENTRY:
			return dictionaryEntriesReducer(state, action);
		case clientTypes.GET_CLIENTS:
			return clientsReducer(state, action);
		case clientTypes.EDIT_CLIENTS:
			return clientsReducer(state, action);
		case clientAccountTypes.CREATE_CLIENT_ACCOUNT_MESSAGE:
			return clientAccountsReducer(state, action);
		case sportsTypes.GET_SPORTS:
			return sportsReducer(state, action);
		case posterEntryTypes.GET_POSTER:
			return posterEntriesReducer(state, action);
		case posterEntryTypes.CREATE_POSTER:
			return posterEntriesReducer(state, action);
		case posterEntryTypes.DELETE_POSTER:
			return posterEntriesReducer(state, action);
		default:
			return state;
	}
}

export default rootReducer;
