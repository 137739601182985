import React, { Component } from "react";
import { connect } from "react-redux";

import ShowFixturesList from "./ShowFixtures";
import GetStationsList from "./GetStations";
import {
	getStations,
	getDictionaryEntries,
	getPosterEntries,
	getClients,
	getSports,
} from "../../_actions/index";
import GetDictionary from "./GetDictionary";
import GetPoster from "./GetPosters";
import GetClients from "./GetClients";

class MainListComponent extends Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		this.props.getStations();
		this.props.getDictionaryEntries();
		this.props.getPosterEntries();
		this.props.getClients();
		this.props.getSports();
	}
	render() {
		return (
			<div className="main-list">
				{this.props.activeTab === "create" ? (
					<ShowFixturesList
						fixtures_type={this.props.fixture_created}
						setKey={this.props.setKey}
						message="You have not created fixtures"
						action="find"
					/>
				) : null}
				{this.props.activeTab === "edit" ? (
					<ShowFixturesList
						fixtures_type={this.props.fixture_edited}
						setKey={this.props.setKey}
						message="You have not updated any fixture"
					/>
				) : null}
				{this.props.activeTab === "find" ? (
					<ShowFixturesList
						fixtures_type={this.props.fixtures}
						setKey={this.props.setKey}
						action="create"
						message="No fixtures matching your search criteria"
					/>
				) : null}
				{this.props.activeTab === "stations" ? (
					<GetStationsList actions title stations={this.props.stations} />
				) : null}
				{this.props.activeTab === "dictionary" ? (
					<GetDictionary dictionary_entries={this.props.dictionary_entries} />
				) : null}
				{this.props.activeTab === "posters" ? (
					<GetPoster poster_option={this.props.poster_option} />
				) : null}
				{this.props.activeTab === "clients" ? (
					<GetClients clients={this.props.clients} />
				) : null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		fixture_created: state.fixture_created,
		fixtures: state.fixtures,
		fixture_edited: state.fixture_edited,
		stations: state.stations,
		dictionary_entries: state.dictionary_entries,
		poster_option: state.poster_option,
		clients: state.clients,
	};
}

export default connect(mapStateToProps, {
	getStations,
	getDictionaryEntries,
	getPosterEntries,
	getClients,
	getSports,
})(MainListComponent);
