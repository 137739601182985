import axios from 'axios';

export function setAuthorizationHeader() {
  if (localStorage.getItem('authentication')) {
    return (axios.defaults.headers.common = {
      Authorization: `Bearer ${localStorage.getItem('authentication')}`,
      'Content-Type': 'application/json'
    });
  }
}
