import React from 'react';
import DatePicker from 'react-datepicker';

const DatePickerInputField = ({
  name,
  value,
  onChange,
  placeholder,
  showTime,
  submitForm,
  timeFormat
}) => {
  return (
    <DatePicker
      selected={(value && new Date(value)) || null}
      onChange={val => {
        if (submitForm) {
          onChange(name, val, false);
          submitForm();
        } else {
          onChange(name, (val ? val : ''));
        }
      }}
      showTimeSelect={showTime ? true : false}
      timeIntervals={15}
      timeFormat="HH:mm"
      minDate={new Date()}
      timeCaption="time"
      placeholderText={placeholder}
      dateFormat={timeFormat ? 'MMMM d, yyyy h:mm aa' : 'MMMM d, yyyy'}
    />
  );
};

export default DatePickerInputField;
