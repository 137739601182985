import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Alert from 'react-bootstrap/Alert';

import { disableAlertMessage } from '../../_actions/index';

const AlertMessage = props => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    setShow(true);
  }, []);
  return props.message && show ? (
    <Alert
      variant={props.variant}
      className="alert_action"
      onClose={() => setShow(false)}
      dismissible
    >
      {props.message}
    </Alert>
  ) : null;
};

export default connect(
  null,
  { disableAlertMessage }
)(AlertMessage);
