import React from "react";
import { connect } from "react-redux";

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { deletePosterEntryById } from "../../_actions";

const GetPoster = (props) => {
	const listItems = props.poster_options ? (
		props.poster_options.reverse().map((posterEntry) => (
			<tr key={posterEntry.id}>
				<td>
					<p>{posterEntry.name}</p>
				</td>

				<td>
					<p
						style={{
							background: posterEntry.color1,
							color: posterEntry.color1,
							border: "1px solid black",
						}}
					>
						{posterEntry.color1}
					</p>
					<p
						style={{
							background: posterEntry.color2,
							color: posterEntry.color2,
							border: "1px solid black",
						}}
					>
						{posterEntry.color2}
					</p>
					<p
						style={{
							background: posterEntry.color3,
							color: posterEntry.color3,
							border: "1px solid black",
						}}
					>
						{posterEntry.color3}
					</p>
				</td>
				<td>
					<p>{posterEntry.bar_logo_type}</p>
				</td>
				<td>
					<img
						src={posterEntry.example}
						alt="example"
						style={{ maxWidth: "250px", maxHeight: "200px" }}
					/>
				</td>
				<td>
					<br />
					<Button
						variant="danger"
						onClick={() => {
							let result = window.confirm(
								"Are you sure you want to delete this poster?"
							);

							if (result) props.deletePosterEntryById(posterEntry.id);
						}}
					>
						Delete
					</Button>
				</td>
			</tr>
		))
	) : (
		<tr>
			<td colSpan="4">{props.message}</td>
		</tr>
	);

	return (
		<div>
			<Table striped bordered responsive>
				<thead>
					<tr>
						<th>Poster name</th>
						<th>Colors</th>
						<th>Logo</th>
						<th>Uploaded files</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>{listItems}</tbody>
			</Table>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		poster_options: state.poster_options,
	};
}
export default connect(mapStateToProps, {
	deletePosterEntryById,
})(GetPoster);
