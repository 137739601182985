import React from 'react';
import { connect } from 'react-redux';

import { editFixture } from '../../_actions';
import { Formik, ErrorMessage } from 'formik';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import moment from 'moment';

import DatePickerInputField from '../inputs/field/DatePicker';
import textInputField from '../inputs/field/Text';
import SelectorInputField from '../inputs/field/Selector';
import validationSchema from '../../_utils/validation';

const EditFixtureForm = props =>
  props.fixture === undefined ? (
    <div>Choose a fixture to edit</div>
  ) : (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          sport: props.fixture.sport,
          title: props.fixture.title,
          name: props.fixture.name,
          country: props.fixture.country,
          time: props.fixture.time
        }}
        validationSchema={validationSchema.FixtureSchema}
        onSubmit={values => {
          values['time'] = moment(values['time']).format();
          props.editFixture(props.fixture.id, values);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          touched,
          errors
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="validationFormikTitle">
              {textInputField(
                'title',
                "fixture's title",
                handleBlur,
                values,
                handleChange,
                touched,
                errors
              )}
            </Form.Group>
            <Form.Group controlId="formGroupName">
              {textInputField(
                'name',
                "fixture's name",
                handleBlur,
                values,
                handleChange,
                touched,
                errors
              )}
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Sport type</Form.Label>
              <SelectorInputField
                onChange={handleChange}
                errors={errors}
                touched={touched}
                sportTypeForEditFixture={props.fixture.sport}
                selectType="sport"
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Country</Form.Label>
              {textInputField(
                'country',
                'country',
                handleBlur,
                values,
                handleChange,
                touched,
                errors
              )}
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridStartDate">
                <Form.Label>Fixture time: </Form.Label>
                <DatePickerInputField
                  name="time"
                  value={
                    values.time
                      ? moment.parseZone(values.time).format('lll')
                      : ''
                  }
                  onChange={setFieldValue}
                  placeholder="Enter date and time"
                  showTime="show"
                  timeFormat="show"
                />
                <ErrorMessage
                  name="time"
                  component="div"
                  className="text-danger"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formEditFixture">
                <Button
                  variant="info"
                  type="submit"
                  className="main-form-fixtures-button"
                >
                  Edit Fixture
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        )}
      </Formik>
    </div>
  );

function mapStateToProps(state) {
  return {
    fixture: state.fixture
  };
}

export default connect(mapStateToProps, { editFixture })(EditFixtureForm);
