import { Formik, ErrorMessage } from 'formik';
import React from 'react';

import { Form, Col, Button } from 'react-bootstrap';
import { uploadDocument } from '../../_actions';
import { connect } from 'react-redux';

const TermsOfService = props => (
  <div>
    <Formik
      initialValues={{ file: null }}
      onSubmit={values => {
        props.uploadDocument(values.file);
      }}
        >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        touched,
        submitForm,
        errors
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Upload Document (.pdf)</Form.Label>
            <Form.Row>
            <input id="file" accept="application/pdf" name="file" type="file" onChange={(event) => {
              setFieldValue("file", event.currentTarget.files[0]);
            }} />
            <ErrorMessage name="currency_id" component="div" className="text-danger" />
            </Form.Row>
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col} controlId="formCreateFixture">
              <Button variant="info" type="submit" className="main-form-upload-button">
                Upload
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    </Formik>
  </div>
);

export default connect(null, { uploadDocument } )(
  TermsOfService
);
