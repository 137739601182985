import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import LoginPage from './Pages/public/Login';
import AdminDashboardPage from './Pages/private/Dashboard';
import PrivateRoute from './PrivateRoute';

const Root = ({ store }) => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Redirect from="/" exact to="/dashboard" />
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <PrivateRoute path="/">
          <AdminDashboardPage />
        </PrivateRoute>
      </Switch>
    </Router>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired
};

export default Root;
