import React from 'react';
import { connect } from 'react-redux';

import { Formik} from 'formik';
import { Button, Form} from 'react-bootstrap';

import textInputField from '../inputs/field/Text';

import { createStations } from '../../_actions';
import validationSchema from '../../_utils/validation';

const CreateStationsForm = props => (
  <div>
    <Formik
      initialValues={{
        station: []
      }}
      validationSchema={validationSchema.StationsSchema}
      onSubmit={value => {
        props.createStations(value.station);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        touched,
        submitForm,
        errors
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="validationFormikStations">
            {textInputField(
              'station',
              "channel",
              handleBlur,
              values,
              handleChange,
              touched,
              errors
            )}
          </Form.Group>
           <Form.Row>
              <Button variant="info" type="submit" className="main-form-fixtures-button">
                Create Channel
              </Button>
          </Form.Row>
        </Form>
      )}
    </Formik>
  </div>
);


export default connect(null, { createStations})(CreateStationsForm);
