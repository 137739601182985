import React from 'react';
import { connect } from 'react-redux';
import { createGames } from '../../../_actions';
import Button from 'react-bootstrap/Button';

const ButtonTypeaheadFormComponent = props => (
  <Button
    onClick={e => {
      return props.stations
        ? props.selectedOptions.map(option => {
            return props.stations.find(station => {
              if (
                (option  === station.name) &&
                !props.isAddChannelButtonClicked
              ) {
                props.createGames(props.fixture_id, station.id);
              }
              return null;
            });
          })
        : null;
    }}
  >
    Add Channels
  </Button>
);

export default connect(null, { createGames })(ButtonTypeaheadFormComponent);
