import React, { Component } from 'react';
import { ListGroup, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  getStations,
  editStations,
  deleteGames,
  deleteStationsById
} from '../../_actions/index';

class GetStations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getSelectedStationById: '',
      isEditButtonClicked: false,
      value: []
    };
  }
  render() {
    const getStationsList = this.props.stations
      ? this.props.stations.map(station => (
          <ListGroup.Item
            key={station.id}
            className="row-style-with-flexbox"
            variant="info"
          >
            {this.state.getSelectedStationById === station.id &&
            this.state.isEditButtonClicked ? (
              <input
                type="text"
                name={station.name}
                value={
                  this.state.value[station.name] ||
                  this.state.value[station.name] === ''
                    ? this.state.value[station.name]
                    : station.name
                }
                onChange={e => {
                  this.setState({
                    value: {
                      [station.name]: e.target.value
                    }
                  });
                }}
              ></input>
            ) : (
              station.name
            )}
            {this.props.actions ? (
              <Button
                variant="dark"
                onClick={e => {
                  this.setState({
                    getSelectedStationById: station.id,
                    isEditButtonClicked: !this.state.isEditButtonClicked
                  });
                  if (
                    this.state.value &&
                    this.state.value[station.name] &&
                    this.state.getSelectedStationById === station.id
                  ) {
                    this.props.editStations(
                      station.id,
                      this.state.value[station.name]
                    );
                  }
                  if (this.state.value[station.name] === '') {
                    this.props.deleteStationsById(station.id);
                  }
                }}
              >
                {this.state.getSelectedStationById === station.id &&
                this.state.isEditButtonClicked
                  ? 'Save'
                  : 'Edit'}
              </Button>
            ) : null}
            {this.props.actions ? (
              <Button
                variant="danger"
                onClick={e => {
                  this.props.deleteStationsById(station.id);
                }}
              >
                Delete
              </Button>
            ) : null}
            {this.props.action ? (
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={e => {
                   this.props.deleteGames(this.props.fixture_id, station.id);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            ) : null}
          </ListGroup.Item>
        ))
      : null;
    return (
      <div>
        {this.props.title ? (
          this.props.stations && this.props.stations.length > 0 ? (
            <ListGroup.Item active className="row-style-with-flexbox">
              All Channels
            </ListGroup.Item>
          ) : (
            <ListGroup.Item className="row-style-with-flexbox">
              No available Channels
            </ListGroup.Item>
          )
        ) : null}
        <ListGroup>{getStationsList}</ListGroup>
      </div>
    );
  }
}
export default connect(null, {
  getStations,
  editStations,
  deleteGames,
  deleteStationsById
})(GetStations);
