import React from 'react';
import Form from 'react-bootstrap/Form';
import { ErrorMessage } from 'formik';
import { connect } from "react-redux";


const SelectorInputField = props => {
  const optionsInputField = props.sports ? 
  props.sports.map(sportsFilter => (
    <option value={sportsFilter.short_name} key={sportsFilter.id}>
      {sportsFilter.short_name}
    </option>
  )) :  <></>;
  return (
    <div>
      <Form.Control
        as="select"
        type={props.selectType}
        name={props.selectType}
        onChange={props.onChange}
      >
        {props.sportTypeForEditFixture ? (
          <option
            value={props.sportTypeForEditFixture}
            label={props.sportTypeForEditFixture}
          />
        ) : (
          <option value="" label="Select sport" />
        )}
        {optionsInputField}
      </Form.Control>
      <ErrorMessage name={props.selectType} component="div" className="text-danger" />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    sports: state.sports
  };
};

export default connect(mapStateToProps)(SelectorInputField);
