import React from 'react';
import { connect } from 'react-redux';

import { Formik, ErrorMessage } from 'formik';
import { Button, Form, Col } from 'react-bootstrap';
import moment from 'moment';

import DatePickerInputField from '../inputs/field/DatePicker';
import textInputField from '../inputs/field/Text';
import SelectorInputField from '../inputs/field/Selector';


import { createFixture, createGames } from '../../_actions';
import validationSchema from '../../_utils/validation';

const CreateFixtureForm = props => (
  <div>
    <Formik
      initialValues={{
        sport: '',
        title: '',
        name: '',
        country: '',
        time: new Date(),
        stations: []
      }}
      validationSchema={validationSchema.FixtureSchema}
      onSubmit={values => {
        function onError(err) {
            alert("Server error");          
        }
        values['time'] = moment(values['time']).format();
        props.createFixture(values, onError);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        touched,
        submitForm,
        errors
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="validationFormikTitle">
            {textInputField(
              'title',
              "fixture's title",
              handleBlur,
              values,
              handleChange,
              touched,
              errors
            )}
          </Form.Group>
          <Form.Group controlId="formGroupName">
            {textInputField(
              'name',
              "fixture's name",
              handleBlur,
              values,
              handleChange,
              touched,
              errors
            )}
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Sport type</Form.Label>
            <SelectorInputField
              onChange={handleChange}
              errors={errors}
              touched={touched}
              selectType="sport"
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Country</Form.Label>
            {textInputField(
              'country',
              'country',
              handleBlur,
              values,
              handleChange,
              touched,
              errors
            )}
          </Form.Group>
          <Form.Row>
              <DatePickerInputField
                name="time"
                value={values.time}
                onChange={setFieldValue}
                placeholder="Enter date and time"
                showTime="show"
                timeFormat="show"
              />
              <ErrorMessage
                name="time"
                component="div"
                className="text-danger"
              />
            <Form.Group as={Col} controlId="formCreateFixture">
              <Button variant="info" type="submit" className="main-form-fixtures-button">
                Create Fixture
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    </Formik>
  </div>
);

function mapStateToProps(state) {
  return {
    stations: state.stations
  };
}

export default connect(mapStateToProps, { createFixture, createGames })(
  CreateFixtureForm
);
