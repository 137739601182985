import React from 'react';
import FixturesNotAvailable from '../message/fixtures/NotAvailable';
import GetFixturesList from '../../components/lists/GetFixtures';

const ShowFixtures = props => {
  return props.fixtures_type === undefined || props.fixtures_type.length === 0 ? (
      <FixturesNotAvailable
        message={props.message}
        action={props.action}
        setKey={props.setKey}
      />
  ) : (
    <GetFixturesList fixtures={props.fixtures_type} setKey={props.setKey} />
  );
};

export default ShowFixtures;
