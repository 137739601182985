import React from 'react';
import { connect } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import TypeaheadFormField from '../form/typeahead';
import moment from 'moment';

import { deleteFixtureById, getFixturesById } from '../../_actions';
import GetStationsList from './GetStations';

const handleTimeFormat = time => {
  return moment.parseZone(time).format('lll');
};
const getStationsByID = (stations, fixtureStationID) => {
  return stations.filter(station => fixtureStationID.find(id => station.id === id));
};
const GetFixturesLists = props => {
  const listItems = props.fixtures ? (
    props.fixtures.map(fixture => (
      <tr key={fixture.id}>
        <td>
          <p>
            <em>{fixture.title}</em>
          </p>
          <p>
            <strong>{fixture.name}</strong>
          </p>
          <p>{fixture.sport_object ? fixture.sport_object.short_name : null}</p>
          <p>{fixture.country}</p>
          <p>{handleTimeFormat(fixture.time)}</p>
        </td>
        {fixture.stations && fixture.stations.length > 0 ? (
          <td>
            <GetStationsList
              stations={fixture.stations}
              action
              fixture_id={fixture.id}
            />
          </td>
        ) : fixture.station_ids && fixture.station_ids.length > 0 ? (
          <td>
            <GetStationsList
              stations={getStationsByID(props.stations, fixture.station_ids)}
              action
              fixture_id={fixture.id}
            />
          </td>
        ) : (
          <td>No available stations</td>
        )}
        <td>
          <TypeaheadFormField fixture_id={fixture.id} />
        </td>
        <td>
          <Button
            variant="info"
            onClick={() => {
              props.setKey('edit');
              props.getFixturesById(fixture.id);
            }}
          >
            Edit
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              props.deleteFixtureById(fixture.id);
            }}
          >
            Delete
          </Button>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="4">{props.message}</td>
    </tr>
  );
  return (
    <div>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>Fixture information</th>
            <th> View Channels</th>
            <th>Add Channels</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{listItems}</tbody>
      </Table>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    stations: state.stations
  };
}
export default connect(mapStateToProps, { deleteFixtureById, getFixturesById })(
  GetFixturesLists
);
