import { stationsTypes } from "../_constants";

function stationsReducer(state, action) {
	switch (action.type) {
		case stationsTypes.GET_STATIONS:
			return { ...state, stations: action.stations };
		case stationsTypes.CREATE_STATIONS:
			if (state.stations) {
				return {
					...state,
					stations: state.stations.concat(action.payload),
				};
			}
			return { ...state, stations: [action.payload] };
		case stationsTypes.EDIT_STATIONS:
			return {
				...state,
				stations: state.stations.map((station) => {
					if (station.id === action.payload.station.id) {
						station.name = action.payload.station.name;
					}
					return station;
				}),
			};
		case stationsTypes.DELETE_STATIONS:
			return {
				...state,
				stations:
					state.stations === undefined
						? []
						: state.stations.filter((item) => item.id !== action.id),
			};
		default:
			return state;
	}
}

export default stationsReducer;
