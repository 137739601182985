import React, { useState } from "react";
import { connect } from "react-redux";

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import { editClientById } from "../../_actions";

const GetClients = (props) => {
	const [clients, setClients] = useState(props.clients);

	const onChange = (id, value, field) => {
		const newClients = clients.map((client) => {
			if (client.id == id)
				if (field == "currency_id") client.currency_id = parseInt(value);
				else if (field == "need_pay") client.need_pay = value == "true";
			return client;
		});
		setClients(newClients);
	};

	const onUpdatePress = (id) => {
		const fclients = clients.filter((client) => client.id === id);
		props.editClientById(id, fclients[0]);
	};

	const listItems = clients ? (
		clients.map((client) => (
			<tr key={client.id}>
				<td>
					<p>{client.name}</p>
				</td>
				<td>
					<select
						name="currency_id"
						value={client.currency_id}
						onChange={(e) => onChange(client.id, e.target.value, "currency_id")}
					>
						<option value="1">Dkk</option>
						<option value="2">Sek</option>
						<option value="5">Nzd</option>
					</select>
				</td>
				<td>
					<select
						name="need_pay"
						value={client.need_pay}
						onChange={(e) => onChange(client.id, e.target.value, "need_pay")}
					>
						<option value="true">Yes</option>
						<option value="false">No</option>
					</select>
				</td>
				<td>
					<Button
						variant="danger"
						onClick={() => {
							onUpdatePress(client.id);
						}}
					>
						Update
					</Button>
				</td>
			</tr>
		))
	) : (
		<tr>
			<td colSpan="4">{props.message}</td>
		</tr>
	);
	return (
		<div>
			<Table striped bordered responsive>
				<thead>
					<tr>
						<th>Name</th>
						<th>Currency</th>
						<th>Need to Pay</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>{listItems}</tbody>
			</Table>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		clients: state.clients,
	};
}
export default connect(mapStateToProps, { editClientById })(GetClients);
