import React from 'react';
import Form from 'react-bootstrap/Form';
import { ErrorMessage } from 'formik';

export const EntryTypes = ["channel","competition","sport","team","country_competition"];

const SelectorDictionaryEntry = props => {
  const optionsInputField = EntryTypes.map(entryType => (
    <option value={entryType} key={entryType}>
      {entryType}
    </option>
  ));
  return (
    <div>
      <Form.Control
        as="select"
        type={props.selectType}
        name={props.selectType}
        onChange={props.onChange}
      >
      <option value="" label="Select type" />
        {optionsInputField}
      </Form.Control>
      <ErrorMessage name={props.selectType} component="div" className="text-danger" />
    </div>
  );
};
export default SelectorDictionaryEntry;
