import {
	alertType,
	fixturesTypes,
	authTypes,
	gamesTypes,
	stationsTypes,
	dictionaryEntryTypes,
	posterEntryTypes,
	clientTypes,
	clientAccountTypes,
	sportsTypes,
	TermsOfServiceTypes,
} from "../_constants";
import { apiRequestService } from "../_services/api/requests";

export function login(loginDetails) {
	return function (dispatch) {
		dispatch(request("login requested"));
		apiRequestService
			.getLoginAuthentication(loginDetails)
			.then((response) => {
				dispatch(success(loginDetails));
				dispatch({
					type: authTypes.LOGIN_AUTHENTICATION,
					payload: response,
				});
			})
			.catch(function (error) {
				dispatch(failure(error));
			});
	};
	function request(message) {
		return { type: authTypes.LOGIN_REQUEST, message };
	}
	function failure(error) {
		return { type: authTypes.LOGIN_ERROR, error };
	}
}
export function success(login_details) {
	return { type: authTypes.LOGIN_SUCCESS, login_details };
}
export function logout() {
	apiRequestService.logout();
	return { type: authTypes.LOG_OUT };
}

export function disableAlertMessage(message) {
	return { type: alertType.ALERT_ACTION, message };
}

export function getFixtures(fixturesDetails) {
	return function (dispatch) {
		apiRequestService.getFixtures(fixturesDetails).then((data) => {
			dispatch({
				type: fixturesTypes.GET_FIXTURES,
				payload: data,
			});
		});
	};
}

export function getFixturesById(id) {
	return function (dispatch) {
		apiRequestService.getFixturesById(id).then((data) => {
			dispatch({
				type: fixturesTypes.GET_FIXTURE_BY_ID,
				payload: data.fixture_with_station,
			});
		});
	};
}

export function getStations() {
	return function (dispatch) {
		apiRequestService.getStations().then((data) => {
			dispatch({
				type: stationsTypes.GET_STATIONS,
				stations: data.stations,
			});
		});
	};
}
export function editStations(id, name) {
	return function (dispatch) {
		apiRequestService.editStations(id, name).then((data) => {
			dispatch({
				type: stationsTypes.EDIT_STATIONS,
				payload: data,
			});
		});
	};
}

export function createStations(name) {
	return function (dispatch) {
		apiRequestService.createStations(name).then((data) => {
			dispatch({
				type: stationsTypes.CREATE_STATIONS,
				payload: data,
			});
		});
	};
}

export function createGames(fixtureID, stationID) {
	return function (dispatch) {
		apiRequestService.createGames(fixtureID, stationID).then((data) => {
			dispatch(getFixturesById(fixtureID));
			dispatch({
				type: gamesTypes.CREATE_GAMES,
				payload: data,
			});
		});
	};
}

export function deleteGames(fixtureID, stationID) {
	return function (dispatch) {
		apiRequestService.deleteGames(fixtureID, stationID).then((data) => {
			dispatch(getFixturesById(fixtureID));
			dispatch({
				type: gamesTypes.DELETE_GAMES,
				payload: data,
			});
		});
	};
}

export function deleteStationsById(id) {
	return function (dispatch) {
		apiRequestService.deleteStationsById(id).then((data) => {
			dispatch({
				type: stationsTypes.DELETE_STATIONS,
				id: id,
				payload: data,
			});
		});
	};
}

export function createFixture(fixturesDetails, err) {
	return function (dispatch) {
		function onSuccess(data) {
			dispatch({
				type: fixturesTypes.CREATE_FIXTURE,
				payload: data.data.fixture,
			});
		}
		apiRequestService.createFixture(fixturesDetails, onSuccess, err);
	};
}

export function editFixture(fixtureID, fixturesDetails) {
	return function (dispatch) {
		apiRequestService.editFixture(fixtureID, fixturesDetails).then((data) => {
			dispatch({
				type: fixturesTypes.EDIT_FIXTURE,
				payload: data.fixture,
			});
		});
	};
}

export function deleteFixtureById(id) {
	return function (dispatch) {
		apiRequestService.deleteFixtureById(id).then((data) => {
			dispatch({
				type: fixturesTypes.DELETE_FIXTURE,
				id: id,
				payload: data,
			});
		});
	};
}

export function getDictionaryEntries() {
	return function (dispatch) {
		apiRequestService.getDictionaryEntries().then((data) => {
			dispatch({
				type: dictionaryEntryTypes.GET_ENTRIES,
				payload: data,
			});
		});
	};
}
export function createDictionaryEntry(values, apply) {
	return function (dispatch) {
		apiRequestService.createDictionaryEntry(values, apply).then((data) => {
			dispatch({
				type: dictionaryEntryTypes.CREATE_ENTRY,
				payload: data,
			});
		});
	};
}
export function deleteDictionaryEntryById(id) {
	return function (dispatch) {
		apiRequestService.deleteDictionaryEntryById(id).then((data) => {
			dispatch({
				type: dictionaryEntryTypes.DELETE_ENTRY,
				id: id,
				payload: data,
			});
		});
	};
}

export function getPosterEntries() {
	return function (dispatch) {
		apiRequestService.getPosterEntries().then((data) => {
			dispatch({
				type: posterEntryTypes.GET_POSTER,
				payload: data,
			});
		});
	};
}
export function createPosterEntry(values, apply) {
	return function (dispatch) {
		apiRequestService.createPosterEntry(values, apply).then((data) => {
			dispatch({
				type: posterEntryTypes.CREATE_POSTER,
				payload: data,
			});
		});
	};
}

export function deletePosterEntryById(id) {
	return function (dispatch) {
		apiRequestService.deletePosterEntryById(id).then((data) => {
			dispatch({
				type: posterEntryTypes.DELETE_POSTER,
				id: id,
				payload: data,
			});
		});
	};
}

export function getClients() {
	return function (dispatch) {
		apiRequestService.getClients().then((data) => {
			dispatch({
				type: clientTypes.GET_CLIENTS,
				payload: data,
			});
		});
	};
}
export function editClientById(id, clientDetails) {
	return function (dispatch) {
		apiRequestService.editClientById(id, clientDetails).then((data) => {
			dispatch({
				type: clientTypes.EDIT_CLIENTS,
				id: id,
				payload: data,
			});
		});
	};
}

export function createClientAccount(values) {
	return function (dispatch) {
		apiRequestService.createClientAccount(values).then((data) => {
			dispatch({
				type: clientAccountTypes.CREATE_CLIENT_ACCOUNT_MESSAGE,
				payload: "",
			});
			dispatch({
				type: clientAccountTypes.CREATE_CLIENT_ACCOUNT_MESSAGE,
				payload: data,
			});
		});
	};
}

export function getSports() {
	return function (dispatch) {
		apiRequestService.getSports().then((data) => {
			dispatch({
				type: sportsTypes.GET_SPORTS,
				sport_objects: data.sport_objects,
			});
		});
	};
}

export function uploadDocument(values) {
	return function (dispatch) {
		apiRequestService.uploadDocument(values).then((data) => {
			dispatch({
				type: TermsOfServiceTypes.UPLOAD_DOCUMENT,
				payload: data,
			});
		});
	};
}
