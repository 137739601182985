import { posterEntryTypes } from "../_constants";

function posterEntriesReducer(state, action) {
	switch (action.type) {
		case posterEntryTypes.GET_POSTER:
			return { ...state, poster_options: action.payload };
		case posterEntryTypes.CREATE_POSTER:
			return {
				...state,
				poster_options: state.poster_options.concat(action.payload),
			};

		case posterEntryTypes.DELETE_POSTER:
			return {
				...state,
				poster_options:
					state.poster_options === undefined
						? []
						: state.poster_options.filter((item) => item.id !== action.id),
			};
		default:
			return state;
	}
}

export default posterEntriesReducer;
