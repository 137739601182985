import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

const getFieldOptions = stations => {
  return stations.map(station => {
    return station.name;
  });
};

const TypeaheadInputComponent = props =>
    <div>
      <Typeahead
        id="stations-id"
        newSelectionPrefix={props.newSelectionPrefix}
        multiple
        options={
          props.stations
            ? getFieldOptions(props.stations)
            : []
        }
        onChange={props.onChange}
        placeholder={props.placeholder}
      />
    </div>

export default TypeaheadInputComponent;
