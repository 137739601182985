import { clientAccountTypes } from '../_constants';

function clientAccountsReducer(state, action) {
  switch (action.type) {
    case clientAccountTypes.CREATE_CLIENT_ACCOUNT_MESSAGE:
      return {
        ...state,
        create_client_account_message: action.payload
      };
    default:
      return state;
  }
}

export default clientAccountsReducer;
