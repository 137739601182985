import React from "react";
import Form from "react-bootstrap/Form";
import { ErrorMessage } from "formik";

export const LogoTypes = ["white_logo", "black_logo"];

const LogoSelector = (props) => {
	const optionsInputField = LogoTypes.map((logoType) => (
		<option value={logoType} key={logoType}>
			{logoType}
		</option>
	));
	return (
		<div>
			<Form.Control
				as="select"
				type={props.selectType}
				name={props.selectType}
				onChange={props.onChange}
			>
				<option value="" label="Select logo" />
				{optionsInputField}
			</Form.Control>
			<ErrorMessage
				name={props.selectType}
				component="div"
				className="text-danger"
			/>
		</div>
	);
};
export default LogoSelector;
