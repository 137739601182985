import React, { useState, useEffect } from "react";
import InputColor from "react-input-color";

const PosterColor = (props) => {
	const [color, setColor] = useState({});
	const { setSelectedColor } = props;

	useEffect(() => {
		if (color) {
			setSelectedColor(color.hex);
		}
	}, [color, setSelectedColor]);

	return (
		<div>
			<InputColor
				initialValue="#5e72e4"
				onChange={setColor}
				placement="right"
			/>
		</div>
	);
};

export default PosterColor;
