import { dictionaryEntryTypes } from '../_constants';

function dictionaryEntriesReducer(state, action) {
  switch (action.type) {
    case dictionaryEntryTypes.GET_ENTRIES:
      return { ...state, dictionary_entries: action.payload };
    case dictionaryEntryTypes.CREATE_ENTRY:
      return {
        ...state,
        dictionary_entries: state.dictionary_entries.concat(action.payload)
      };
    // case dictionaryEntryTypes.EDIT_STATIONS:
    //   return {
    //     ...state,
    //     stations: state.stations.map(station => {
    //       if (station.id === action.payload.station.id) {
    //         station.name = action.payload.station.name;
    //       }
    //       return station;
    //     })
    //   };
    case dictionaryEntryTypes.DELETE_ENTRY:
      return {
        ...state,
        dictionary_entries:
          state.dictionary_entries === undefined
            ? []
            : state.dictionary_entries.filter(item => item.id !== action.id)
      };
    default:
      return state;
  }
}

export default dictionaryEntriesReducer;
