import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from 'react-bootstrap';

import { logout } from '../../_actions/index';
import { setAuthorizationHeader } from '../../_utils/auth/header';

import AlertMessage from '../../components/message/Alert';

import MainListComponent from '../../components/lists/Main';
import MainFormComponent from '../../components/form/Main';

import './Dashboard.scss';

const AdminDashboardPage = props => {
  let [activeTab, setKey] = useState('create client accounts');
  setAuthorizationHeader();
  let history = useHistory();
  function handleLogOut() {
    props.logout();
    setTimeout(history.push('/login'), 100);
  }

  return (
    <div className="dashboard">
      <div className="row-style-with-flexbox">
        <p>
          Welcome,
          {props.username !== undefined
            ? props.username.match(/([^@]+)/i)[0]
            : ''}
        </p>
        <Button variant="primary" onClick={handleLogOut}>
          Log out
        </Button>
      </div>
      <br />
      {props.fixture_deleted ? (
        <AlertMessage message={props.fixture_deleted} variant="success" />
      ) : null}
      {props.create_client_account_message ? (
        <AlertMessage message={typeof props.create_client_account_message == "object" ? 
                              ("Created account for " + props.create_client_account_message.email) : 
                              props.create_client_account_message} variant="success" />
      ) : null}
      <div className="dashboard-body">
        <MainFormComponent setKey={setKey} activeTab={activeTab} />
        <MainListComponent setKey={setKey} activeTab={activeTab} />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    error_message: state.error_message,
    fixture_deleted: state.fixture_deleted,
    username: state.username,
    create_client_account_message: state.create_client_account_message
  };
}

export default connect(mapStateToProps, { logout })(AdminDashboardPage);
