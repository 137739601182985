import React from 'react';
import { connect } from 'react-redux';

import { Formik, ErrorMessage } from 'formik';
import { Button, Form, Col } from 'react-bootstrap';

import textInputField from '../inputs/field/Text';

import { createClientAccount } from '../../_actions';
import validationSchema from '../../_utils/validation';

const CreateClientAcountForm = props => (
  <div>
    <Formik
      initialValues={{
        client_name: '',
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        role: 'ADMIN',
        currency_id: 1
      }}
      validationSchema={validationSchema.ClientAccountSchema}
      onSubmit={values => {
        props.createClientAccount(values);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        touched,
        submitForm,
        errors
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="validationFormikTitle">
            {textInputField(
              'client_name',
              "Company Name",
              handleBlur,
              values,
              handleChange,
              touched,
              errors
            )}
          </Form.Group>
          <Form.Group controlId="formGroupName">
            {textInputField(
              'name',
              "user's name",
              handleBlur,
              values,
              handleChange,
              touched,
              errors
            )}
          </Form.Group>
          <Form.Group controlId="formGroupName">
            {textInputField(
              'email',
              "user's email",
              handleBlur,
              values,
              handleChange,
              touched,
              errors
            )}
          </Form.Group>
          <Form.Group controlId="formGroupPassword">
            {textInputField(
              'password',
              "password",
              handleBlur,
              values,
              handleChange,
              touched,
              errors
            )}
          </Form.Group>
          <Form.Group controlId="formGroupPassword">
            {textInputField(
              'password_confirmation',
              'password confirmation',
              handleBlur,
              values,
              handleChange,
              touched,
              errors,
              null,
              'password'
            )}
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Role</Form.Label>
            <Form.Control
              as="select"
              name="role" 
              type="role"
              onChange={handleChange}
            >
              <option value="ADMIN">ADMIN</option>
              <option value="VIEWONLY">VIEWONLY</option>
            </Form.Control>
            <ErrorMessage name="role" component="div" className="text-danger" />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Currency</Form.Label>
            <Form.Control
              as="select"
              name="currency_id" 
              type="currency_id"
              onChange={handleChange}
            >
              <option value="1">dkk</option>
              <option value="2">sek</option>
	            <option value="5">Nzd</option>
            </Form.Control>
            <ErrorMessage name="currency_id" component="div" className="text-danger" />
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col} controlId="formCreateFixture">
              <Button variant="info" type="submit" className="main-form-fixtures-button">
                Create Client Account
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    </Formik>
  </div>
);

function mapStateToProps(state) {
  return {
    clients_accounts_created: state.fixtures_created,
  };
}

export default connect(mapStateToProps, { createClientAccount })(
  CreateClientAcountForm
);
