import React from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import FindFixturesForm from "../../components/form/FindFixtures";
import EditFixtureForm from "../../components/form/EditFixture";
import CreateFixtureForm from "../../components/form/CreateFixture";
import CreateStationsForm from "../../components/form/CreateStations";
import DictionaryForm from "../../components/form/DictionaryEntry";
import ClientForm from "../../components/form/ClientPH";
import ClientAccountForm from "../../components/form/CreateClientAccount";
import TermsOfService from "../../components/form/TermsOfService";
import ManagePosters from "../../components/form/Posters";

const MainFormComponent = (props) => {
	return (
		<div className="main-form">
			<Card>
				<Card.Body>
					<Tabs
						onSelect={(k) => props.setKey(k)}
						activeKey={props.activeTab}
						id="uncontrolled-tab-example"
						className="main-form-tab-spacing"
					>
						<Tab eventKey="find" title="Find Fixture">
							<FindFixturesForm />
						</Tab>
						<Tab eventKey="create" title="Create Fixture">
							<CreateFixtureForm className="main-form-tab-spacing" />
						</Tab>
						<Tab eventKey="stations" title="Channels">
							<CreateStationsForm />
						</Tab>
						<Tab eventKey="edit" title="Edit Fixture" disabled>
							<EditFixtureForm />
						</Tab>
						<Tab eventKey="dictionary" title="Dictionary">
							<DictionaryForm />
						</Tab>
						<Tab eventKey="clients" title="Clients">
							<ClientForm />
						</Tab>
						<Tab eventKey="posters" title="Posters">
							<ManagePosters />
						</Tab>
						<Tab
							eventKey="create client accounts"
							title="Create Client account"
						>
							<ClientAccountForm />
						</Tab>
						<Tab eventKey="terms of use" title="Terms of Service">
							<TermsOfService />
						</Tab>
					</Tabs>
				</Card.Body>
			</Card>
		</div>
	);
};

export default MainFormComponent;
